<clr-main-container>
    <clr-header *ngIf="router.url != '/' && router.url != '/reset-password' && router.url != '/registrazione-tecnico' && router.url != '/registrazione-agenzia' && router.url != '/thankyou-registrazione' && !router.url.startsWith('/conferma-mail') && !router.url.startsWith('/download')">
        <div class="branding">
            <div class="header-logo">
                <a routerLink="/stats"><img src="../assets/img/logo_OK.png"/></a>
            </div>
        </div>
        <div class="header-nav" [clr-nav-level]="1">
            <a routerLink="/pratiche" class="nav-link nav-text" [ngClass]="{'active': router.url.startsWith('/pratic')}">Pratiche</a>
            <a routerLink="/stats" class="nav-link nav-text" [ngClass]="{'active': router.url == '/stats'}">Statistiche</a>
            <a *ngIf="showMenuAnagrafica" routerLink="/anagrafica-societa" class="nav-link nav-text" [ngClass]="{'active': router.url == '/anagrafica-societa'}">Anagrafica agenzia</a>
            <a *ngIf="auth.isTecnico" routerLink="/anagrafica-tecnico" class="nav-link nav-text" [ngClass]="{'active': router.url == '/anagrafica-tecnico'}">Anagrafica tecnico</a>
            <a *ngIf="auth.isConsulente" routerLink="/utente" class="nav-link nav-text" [ngClass]="{'active': router.url == '/utente'}">Anagrafica Utente</a>
            <a *ngIf="showMenuUtenti" routerLink="/utenti" class="nav-link nav-text" [ngClass]="{'active': router.url.startsWith('/utent')}">Utenti</a>
            <a *ngIf="auth.isAdmin" routerLink="/admin/agenzie" class="nav-link nav-text" [ngClass]="{'active': router.url == '/admin/agenzie'}">Agenzie</a>
            <a *ngIf="auth.isTeamLeader" routerLink="/agenzie-select" class="nav-link nav-text" [ngClass]="{'active': router.url == '/agenzie-select'}">Agenzie</a>
            <a *ngIf="auth.isAdmin" routerLink="/admin/tecnici" class="nav-link nav-text" [ngClass]="{'active': router.url == '/admin/tecnici'}">Tecnici</a>
            <a *ngIf="auth.isAdmin" routerLink="/admin/teamleaders" class="nav-link nav-text" [ngClass]="{'active': router.url == '/admin/teamleaders'}">Team Leaders</a>
            <a *ngIf="!auth.isAdmin" routerLink="/assistenza" class="nav-link nav-text" [ngClass]="{'active': router.url == '/assistenza'}">Assistenza</a>
        </div>
        <div class="header-actions">
            <a href="javascript://" class="nav-link nav-text" (click)="auth.logout()">Logout</a>
        </div>
    </clr-header>
    <div class="content-container">
        <div class="router-outlet">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>

<div class="spinner-full" [ngClass]="{'active': spinner.stack > 0}">
    <div class="spinner-wrapper">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</div>
